<template>
  <b-modal
    id="movement-route-lesson-section-edit-modal"
    :title="titleModal"
    size="xl"
    centered
    @hide="resetModal"
  >
    <b-container>
      <form id="lesson-section-edit-form" @submit.prevent="handleValidate">
        <b-row>
          <b-col class="border-right">
            <basic-input
              :required="true"
              label="Mã nhóm động tác"
              name="code"
              placeholder="Nhập mã nhóm động tác"
              v-model="form.code"
              :state="validateState('code')"
              :invalidFeedback="errors.first('code')"
              v-validate="{
                required: true,
                alpha_num: true,
                max: 50,
                valid_code: validCode,
              }"
              data-vv-as="Mã nhóm động tác"
              maxlength="50"
            />
            <basic-input
              :required="true"
              label="Tên nhóm động tác"
              name="name"
              placeholder="Nhập tên nhóm động tác"
              v-model="form.name"
              :state="validateState('name')"
              :invalidFeedback="errors.first('name')"
              v-validate="'required|max:100'"
              data-vv-as="Tên nhóm động tác"
              maxlength="100"
            />
            <basic-select
              label="Mã vận động"
              name="exerciseCategoryId"
              :options="exerciseCategoryOptions"
              placeholder="---Chọn mã vận động---"
              :value.sync="form.exerciseCategoryId"
              track-by="value"
              value-label="text"
              @update:value="
                selected($event, 'exerciseCategoryId', exerciseCategoryOptions)
              "
            />
            <div v-if="form.exerciseCategoryId">
              Tên vận động: {{ form.exerciseCategoryId.name }}
            </div>
          </b-col>
          <b-col>
            <label>Video động tác <span style="color: #ff0000">*</span></label>
            <basic-file-input
              v-if="!form.videoUrl"
              class="mb-6"
              title="Tải lên video"
              name="video"
              :state="validateState('video')"
              :invalidFeedback="errors.first('video')"
              v-validate="{
                required: !form.videoUrl && !form.videoFile,
              }"
              data-vv-as="Video động tác"
              @uploaded="handleUploaded"
            >
              <template v-slot:uploadedFile>
                <video
                  id="video"
                  v-if="videoPreview"
                  :src="videoPreview"
                  class="w-100"
                  controls
                ></video>
              </template>
            </basic-file-input>
            <template v-if="!videoPreview">
              <basic-input
                name="videoUrl"
                placeholder="Nhập đường dẫn video"
                v-model="form.videoUrl"
                :state="validateState('videoUrl')"
                :invalidFeedback="errors.first('videoUrl')"
                v-validate="{
                  required: !form.videoFile && !form.videoUrl,
                  valid_video_url: true,
                }"
                data-vv-as="Đường dẫn video"
              />
              <video
                id="video"
                v-if="form.videoUrl"
                :src="form.videoUrl"
                class="w-100 mb-4"
                controls
              ></video>
            </template>
            <b-row>
              <b-col cols="6">
                <basic-select
                  :required="true"
                  label="Số lần replay video"
                  :options="replayTimeOptions"
                  value-label="text"
                  track-by="value"
                  :allow-empty="false"
                  placeholder="--- Chọn số lần replay ---"
                  :value.sync="form.replayTime"
                  :state="validateState('replayTime')"
                  :invalidFeedback="errors.first('replayTime')"
                  v-validate="'required'"
                  data-vv-as="Số lần replay video"
                  @update:value="
                    selected($event, 'replayTime', replayTimeOptions)
                  "
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
    </b-container>

    <template slot="modal-footer">
      <div class="d-flex justify-content-end align-items-center">
        <b-button class="btn mr-3" @click="onClickCancelButton"> Hủy</b-button>
        <b-button
          class="btn btn-success"
          type="submit"
          form="lesson-section-edit-form"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
import BasicFileInput from '../lesson/BasicFileInput';

export default {
  name: 'MovementRouteLessonSectionEdit',
  components: { BasicFileInput },
  props: {
    section: {
      type: Object,
      default: () => {
        return {
          code: null,
          name: null,
          exerciseCategoryId: null,
          videoUrl: null,
          replayTime: null,
        };
      },
    },
    lessonSections: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: MODAL_TYPE.DETAIL,
    },
  },
  watch: {
    section: {
      deep: true,
      handler(val) {
        if (!Object.keys(val).length) return;
        this.form = {
          ...val,
          exerciseCategoryId: this.exerciseCategoryOptions.find(
            (exerciseCategoryId) =>
              exerciseCategoryId.value == val.exerciseCategoryId,
          ),
          replayTime: this.replayTimeOptions.find(
            (replayTime) => replayTime.value == val.replayTime,
          ),
        };
      },
    },
    'form.code': function (val) {
      const code = this.section.code;
      if (!val || code === val) return (this.validCode = false);
      this.checkDuplicateCode(val);
    },
  },
  data() {
    return {
      url: {
        exerciseCategoryList: '/Admin/Exercise/Category',
      },
      form: {
        code: null,
        name: null,
        exerciseCategoryId: null,
        videoFile: null,
        videoUrl: null,
        replayTime: {
          text: '1',
          value: 1,
        },
      },
      validCode: false,
      videoPreview: null,
      exerciseCategoryOptions: [],
      replayTimeOptions: Array.from({ length: 20 }, (_, i) => {
        return {
          text: `${i + 1}`,
          value: i + 1,
        };
      }),
    };
  },
  computed: {
    titleModal() {
      switch (this.type) {
        case MODAL_TYPE.CREATE:
          return 'Tạo nhóm động tác mới';
        case MODAL_TYPE.EDIT:
          return 'Chỉnh sửa động tác';

        default:
          return 'Xem chi tiết động tác';
      }
    },
  },
  created() {
    this.getData();
  },
  methods: {
    resetModal() {
      this.form = {
        code: null,
        name: null,
        exerciseCategoryId: null,
        videoFile: null,
        videoUrl: null,
        replayTime: {
          text: '1',
          value: 1,
        },
      };
      this.videoPreview = null;
      this.$emit('reset');
    },
    closeModal() {
      this.$bvModal.hide('movement-route-lesson-section-edit-modal');
    },
    onClickCancelButton() {
      this.closeModal();
    },
    onClickSaveButton() {
      const video = document.querySelector('#video');
      const duration = Math.floor(video.duration);
      if (this.type === MODAL_TYPE.CREATE) {
        this.$emit('create', {
          ...this.form,
          exerciseCategoryId: this.form.exerciseCategoryId?.value,
          replayTime: this.form.replayTime?.value,
          duration,
        });
      } else if (this.type === MODAL_TYPE.EDIT) {
        this.$emit('edit', {
          ...this.form,
          exerciseCategoryId: this.form.exerciseCategoryId?.value,
          replayTime: this.form.replayTime?.value,
          duration,
        });
      }
      this.closeModal();
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result && !this.validCode) {
          this.onClickSaveButton();
        }
        return;
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    selected(value, formProperty, optionsArr) {
      this.form[formProperty] = value
        ? optionsArr.find((el) => el.value == value)
        : null;
      if (this.form[formProperty]) {
        this.$validator.reset({ name: formProperty });
      }
    },
    getData() {
      this.$api
        .get(this.url.exerciseCategoryList, {
          params: {
            default: false,
            takeAll: true,
          },
        })
        .then((res) => {
          const data = res.data;

          this.exerciseCategoryOptions = data.map((item) => {
            return {
              text: `${item.code} - ${item.name}`,
              value: item.id,
              name: item.name,
            };
          });
        });
    },
    handleUploaded({ file, url }) {
      this.form.videoFile = file;
      this.videoPreview = url;
    },
    async checkDuplicateCode(code) {
      const sameCodeSectionIndex = this.lessonSections.findIndex(
        (el) => el.code === code,
      );

      if (sameCodeSectionIndex >= 0) {
        return (this.validCode = true);
      }
      try {
        const { meta, error, data } = await this.$api.get(
          '/ExerciseMovement/Sections/CheckDuplicateCode',
          {
            params: {
              code,
            },
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.validCode = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.image-upload {
  width: 240px;
  height: 120px;
  background-color: #f3f6f9;
  box-shadow: 0px 4px 4px 0px #000000 10%;
  position: relative;
  margin: auto;
}

.file-input-dashed {
  height: 67px;
  border: 2px dashed #dddddd;

  span {
    font-size: 16px;
    color: #787a7d;
  }
}
</style>
