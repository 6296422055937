<template>
  <b-modal
    id="movement-route-lesson-section-select-modal"
    title="Chọn nhóm động tác có sẵn"
    size="md"
    centered
    @hide="clearData"
  >
    <basic-select
      label="Chọn nhóm động tác có sẵn"
      placeholder="--- Chọn nhóm động tác có sẵn ---"
      name="section"
      required
      :solid="false"
      :allow-empty="false"
      :value.sync="selectedSection"
      :options="sectionOptions"
      value-label="name"
      track-by="id"
      changeValueByObject
      :state="validateState('section')"
      :invalidFeedback="errors.first('section')"
      v-validate="'required'"
      data-vv-as="Nhóm động tác"
      @update:value="onSelectSection"
    />
    <template slot="modal-footer">
      <div class="d-flex justify-content-end align-items-center">
        <b-button class="btn mr-3" @click="onClickCancelButton"> Hủy</b-button>
        <b-button
          class="btn btn-success"
          @click="handleValidate"
          form="lesson-section-edit-form"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'MovementRouteLessonSectionSelect',
  data() {
    return {
      url: {
        getSections: '/ExerciseMovement/Sections',
      },
      sectionOptions: [],
      selectedSection: null,
    };
  },
  created() {
    this.loadSections();
  },
  methods: {
    clearData() {
      this.selectedSection = null;
    },
    async loadSections() {
      let { data } = await this.$api.get(this.url.getSections);
      this.sectionOptions = data;
    },
    onSelectSection() {
      this.$validator.reset({ name: 'section' });
    },
    closeModal() {
      this.$bvModal.hide('movement-route-lesson-section-select-modal');
    },
    onClickCancelButton() {
      this.clearData();
      this.closeModal();
    },
    onClickSaveButton() {
      this.$emit('select', this.selectedSection);
      this.clearData();
      this.closeModal();
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.onClickSaveButton();
        }
        return;
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style scoped></style>
